import * as Hooks from './hooks'
import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as Suspense from './suspense'
import * as ThemeUi from 'theme-ui'

import {lazy as loadable} from '@loadable/component'

const ExternalFont = loadable(() => import('./external-font'))
const SeoCanonicals = loadable(() => import('./seo-canonicals'))
const SeoMetadataContainer = loadable(() => import('./seo-meta-data'))
const Topbar = loadable(() => import('./topbar'))
const LandingPage = loadable(() => import('./landing-page'))
const Footer = loadable(() => import('./footer'))
const StaticPage = loadable(() => import('./static-page'))
const Register = loadable(() => import('./register'))
const Login = loadable(() => import('./login'))
const Logout = loadable(() => import('./logout'))
const GameLauncher = loadable(() => import('./game-launcher'))
const NotFound = loadable(() => import('./not-found'))
const PasswordRecovery = loadable(() => import('./password-recovery'))
const PasswordReset = loadable(() => import('./password-reset'))
const PasswordResetContainer = loadable(() =>
  import('./password-reset-container')
)
const NotificationsContainer = loadable(() => import('./notifications'))
const CookieConsentBanner = loadable(() => import('./cookie-consent-banner'))

export function App() {
  Hooks.useDevicePixelRatioListener()
  Hooks.useWebsocketListener()
  Hooks.useGoogleDataLayerManager()
  Hooks.usePageview()
  Hooks.useSessionListener()
  Hooks.useNavigationScrollTop()
  Hooks.useClientTypeObserver()

  return (
    <Suspense.Boundary>
      <Suspense.Boundary fallback={null}>
        <SeoMetadataContainer />
        <SeoCanonicals />
        <ExternalFont />
      </Suspense.Boundary>
      <ReactRouter.Route path="/">
        <Suspense.Boundary fallback={<header />}>
          <Topbar />
        </Suspense.Boundary>
      </ReactRouter.Route>
      <ThemeUi.Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: 'calc(100dvh - 56px)',
          position: 'relative',
        }}
      >
        <Suspense.Boundary>
          <ReactRouter.Switch>
            <ReactRouter.Route exact path="/">
              <LandingPage />
            </ReactRouter.Route>

            <ReactRouter.Route path="/play/:gameId">
              <GameLauncher />
            </ReactRouter.Route>

            <ReactRouter.Route path="/(cookie-policy|privacy-policy|terms-and-conditions)">
              <StaticPage />
            </ReactRouter.Route>

            <ReactRouter.Route path="/logout">
              <Logout />
            </ReactRouter.Route>

            <ReactRouter.Route path="/en/password/:token">
              <PasswordResetContainer />
            </ReactRouter.Route>

            <ReactRouter.Route>
              <NotFound />
            </ReactRouter.Route>
          </ReactRouter.Switch>
        </Suspense.Boundary>
      </ThemeUi.Box>
      <Suspense.Boundary fallback={null}>
        <Footer />
      </Suspense.Boundary>
      <Suspense.Boundary fallback={null}>
        <Login />
        <Register />
        <PasswordRecovery />
        <PasswordReset />
        <NotificationsContainer />
        <CookieConsentBanner />
      </Suspense.Boundary>
    </Suspense.Boundary>
  )
}
