import * as Configuration from './configuration'
import * as Herz from '@rushplay/herz'
import * as Suspense from './suspense'
import * as Urql from 'urql'

import {App} from './app'
import {GlobalProvider} from './global-provider'
import React from 'react'
import {createStore} from './create-store'
import {hydrate} from 'react-dom'
import {loadableReady} from '@loadable/component'

// Picks up redux-store from server handover
const store = createStore({initialState: window.__APP_DATA__})
// Removes the object from window
delete window.__APP_DATA__

const ssrCache = Urql.ssrExchange({
  initialState: window.__GQL_DATA__,
  isClient: true,
})

const state = store.getState()

// the client-side of the application
async function main() {
  const loader = new Herz.I18n.Loader({
    provider: Herz.I18n.SourceType.BABELFISK,
    url: Configuration.getI18nProviderUrl(state.configuration),
    transformAlias(key) {
      return `boomcasinofun.${key}`
    },
    debug: true,
  })

  await loader.restoreCache(window.__I18N_DATA__)

  loadableReady(() => {
    hydrate(
      <GlobalProvider
        translationLoader={loader}
        store={store}
        ssrCache={ssrCache}
      >
        <Suspense.Boundary>
          <App />
        </Suspense.Boundary>
      </GlobalProvider>,
      document.getElementById('root')
    )
  })
}

main()

if (module.hot) {
  module.hot.accept()
}
