/**
 * Theme data for `theme-ui`
 * @constant {Object}
 * @see {@link https://theme-ui.com/theming#example-theme}
 */
export const defaultTheme = {
  breakpoints: ['700px', '1000px'],
  space: [4, 8, 16, 24, 32, 40, 48],
  shadows: ['0 3px 14px 0 rgba(0, 0, 0, 0.1)'],
  fonts: {
    heading: 'Poppins, Helvetica, Arial, sans-serif',
    body: 'Poppins, Helvetica, Arial, sans-serif',
  },
  fontSizes: [12, 14, 16, 20, 24, 32],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    'background': '#17061D',
    'danger': '#FA5847',
    'info': '#1FB6BE',
    'success': '#009F66',
    'warning': '#FFC956',
    'footer-background': '#0F0314',
    'game-tile-background': '#291332',
    'input-error-bg': '#FFDADA',
    'input-focus': '#1EB6BE',
    'input-placeholder': '#989898',
    'nav-background': '#291332',
    'nav-dropdown-button-background': '#371B43',
    'primary-button': '#019F66',
    'secondary-button': '#1EB6BE',
    'static-black': '#000000',
    'static-white': '#FFFFFF',
    'text': '#FFFFFF',
  },
  radii: [4, 8, 16, 9999],
  text: {
    color: 'text',
    title: {
      fontSize: 2,
    },
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
  },
  sizes: {
    container: {
      lg: '1328px',
    },
  },
  styles: {
    spinner: {
      color: 'static-white',
    },
    hr: {
      my: 0,
      mx: '0px',
      color: 'muted',
    },
    root: {
      minHeight: '100dvh',
      display: 'flex',
      m: '0px',
      bg: 'background',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      WebkitOverflowScrolling: 'touch',
    },
  },
  forms: {
    input: {
      'py': '6px',
      'px': 2,
      'fontSize': 2,
      'fontFamily': 'body',
      'backgroundColor': 'static-white',
      'borderRadius': 0,
      'transition':
        'border-color 150ms ease-in-out, background-color 150ms ease-in-out',
      'lineHeight': 'body',
      'color': 'static-black',
      'outline': 'none',
      'border': '2px solid',
      'borderColor': 'static-white',
      '&:focus': {
        borderColor: 'input-focus',
      },
      '&:disabled, &:read-only': {
        cursor: 'not-allowed',
      },
      '&::placeholder': {
        fontWeight: 'body',
        color: 'input-placeholder',
      },
    },
    select: {
      'py': '6px',
      'px': 2,
      'fontSize': 2,
      'backgroundColor': 'static-white',
      'borderRadius': 0,
      'color': 'text',
      'transition': 'border-color 150ms ease-in-out',
      'lineHeight': 'body',
      'border': '2px solid',
      'outline': 'none',
      'borderColor': 'static-white',
      '&:focus': {
        borderColor: 'input-focus',
      },
      '&:disabled, &:read-only': {
        'cursor': 'not-allowed',
        'borderColor': 'disabled',
        '&:hover': {
          borderColor: 'disabled',
        },
      },
    },
    checkbox: {
      'cursor': 'pointer',
      'borderRadius': 0,
      'color': 'inherit',
      'bg': 'static-white',
      'borderColor': 'inherit',
      '> path': {
        color: 'transparent',
      },
    },
    checkboxLabel: {
      'fontSize': 1,
      'display': 'grid',
      'gridGap': 2,
      'gridTemplateColumns': '24px 1fr',
      'lineHeight': 'body',
      'cursor': 'pointer',
      'input:focus ~ svg': {
        border: '2px solid',
        borderColor: 'input-focus',
        bg: 'static-white',
      },
      'input:checked ~ svg': {
        'bg': 'static-white',
        'border': 'none',
        'boxShadow': 'inset 0 0 0 4px #009F66',
        '> path': {
          color: 'inherit',
        },
      },
    },
    label: {
      fontSize: 1,
      fontWeight: 'body',
      fontFamily: 'body',
      color: 'static-white',
      mb: '4px',
    },
  },
  buttons: {
    primary: {
      'outline': 'none',
      'fontSize': [1, 2],
      'backgroundColor': 'primary-button',
      'py': '0.5em',
      'px': '1.5em',
      'fontWeight': 'bold',
      'fontFamily': 'heading',
      'lineHeight': '20px',
      'transition': 'transform 150ms ease-in-out',
      'cursor': 'pointer',
      'borderRadius': 3,
      '&:hover, &:focus': {
        opacity: 0.9,
      },
      '&:active': {
        transform: 'scale(0.975)',
      },
      '&:disabled': {
        'bg': 'rgba(255, 255, 255, 0.4)',
        'boxShadow': 'none',
        'cursor': 'not-allowed',
        'color': 'text',
        '&:hover': {
          opacity: 1,
        },
      },
    },
    secondary: {
      'outline': 'none',
      'fontSize': [1, 2],
      'fontFamily': 'heading',
      'backgroundColor': 'secondary-button',
      'py': '0.5em',
      'px': '1.5em',
      'fontWeight': 'bold',
      'lineHeight': '20px',
      'transition': 'transform 150ms ease-in-out',
      'cursor': 'pointer',
      'borderRadius': 3,
      '&:hover, &:focus': {
        opacity: 0.9,
      },
      '&:active': {
        transform: 'scale(0.975)',
      },
      '&:disabled': {
        'bg': 'rgba(255, 255, 255, 0.4)',
        'color': 'text',
        'cursor': 'not-allowed',
        '&:hover': {
          opacity: 1,
        },
      },
    },
    muted: {
      'outline': 'none',
      'fontSize': [1, 2],
      'fontFamily': 'heading',
      'color': 'static-white',
      'backgroundColor': 'transparent',
      'borderRadius': 3,
      'border': '2px solid white',
      'py': '0.5em',
      'px': '1.5em',
      'fontWeight': 'bold',
      'lineHeight': '20px',
      'transition':
        'transform 150ms ease-in-out, background-color 150ms ease-in-out, color 150ms ease-in-out',
      'cursor': 'pointer',
      '&:hover, &:focus': {
        backgroundColor: 'static-white',
        color: 'nav-background',
      },
      '&:active': {
        transform: 'scale(0.975)',
        color: 'nav-background',
      },
      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
  },
  links: {
    footerNav: {
      'cursor': 'pointer',
      'fontSize': 1,
      'color': 'static-white',
      'opacity': 0.6,
      'textDecoration': 'none',
      'm': 1,
      '&:hover': {
        textDecoration: 'underline',
        opacity: 1,
      },
    },
    dropdownNav: {
      'cursor': 'pointer',
      'fontSize': 2,
      'fontWeight': 'bold',
      'color': 'text',
      'textDecoration': 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  messages: {
    info: {
      bg: 'infoMuted',
      borderLeftWidth: '3px',
      borderLeftColor: 'info',
      p: 1,
      width: '100%',
    },
    fieldError: {
      'border': 'none',
      'wordBreak': 'break-word',
      'position': 'absolute',
      'maxWidth': '100%',
      'width': 'auto',
      'bottom': '0px',
      'transform': 'translateY(110%)',
      'right': '0px',
      'bg': 'static-white',
      'color': 'text',
      'boxShadow': '2px 2px 7px 0 rgba(0,0,0,0.7)',
      'mr': '8px',
      'display': 'flex',
      'fontSize': 0,
      'padding': '0px',
      'zIndex': 5,
      '&::after': {
        position: 'absolute',
        top: '-9px',
        right: '15px',
        content: '""',
        width: '0px',
        height: '0px',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderBottom: '10px solid #FFF',
      },
    },
  },
}
