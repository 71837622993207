import * as Api from '@rushplay/api-client'
import * as Constants from './constants'
import * as Cookies from './cookies'
import * as Logger from './logger'
import * as R from 'ramda'
import * as ReduxEffects from 'redux-effects'
import * as Reselect from 'reselect'

const EMPTY_ARRAY = Object.freeze([])
const EMPTY_OBJECT = Object.freeze({})

const INITIAL_STATE = {
  apiUrl: process.env.API_URL,
  i18nProviderUrl: process.env.I18N_PROVIDER_URL,
  gameServerUrl: process.env.GAME_SERVER_URL,
  gqlUrl: process.env.GRAPHQL_URL,
  babelFiskImgProxyUrl: process.env.BABELFISK_IMGPROXY_URL,
  imgproxyUrl: process.env.IMGPROXY_URL,
  version: process.env.RAZZLE_APP_VERSION,
}

export const CLIENT_TYPE_UPDATED = 'boom/config/CLIENT_TYPE_UPDATED'
export const COUNTRY_CODE_UPDATED = 'boom/config/COUNTRY_CODE_UPDATED'
export const DEVICE_PIXLE_RATIO_UPDATED =
  'boom/config/DEVICE_PIXLE_RATIO_UPDATED'
export const INIT = 'boom/config/INIT'
export const WEBP_SUPPORT_UPDATED = 'boom/config/WEBP_SUPPORT_UPDATED'

export function init(configuration) {
  return {
    type: INIT,
    error: configuration instanceof Error,
    payload: configuration,
  }
}

export function updateClientType(clientType) {
  const supportedClientTypes = R.values(Constants.ClientType)

  if (R.not(R.includes(clientType, supportedClientTypes))) {
    if (process.env.NODE_ENV !== 'production') {
      Logger.root.warn({clientType}, 'unsupported client type detected')
    }

    // Change client type to unknown if unsupported value is given
    return updateClientType(Constants.ClientType.UNKNOWN)
  }

  return {
    type: CLIENT_TYPE_UPDATED,
    payload: clientType,
  }
}

export function updateCountryCode(countryCode) {
  return {
    type: COUNTRY_CODE_UPDATED,
    payload: countryCode,
  }
}

export function updateDevicePixleRatio(payload) {
  return ReduxEffects.bind(
    Cookies.set(Constants.CookieKeys.DEVICE_PIXEL_RATIO, payload, {
      maxAge: 180 * 24 * 60 * 60 * 1000,
      path: '/',
    }),
    () => ({
      type: DEVICE_PIXLE_RATIO_UPDATED,
      payload,
    })
  )
}

export function updateWebpSupport(boolean) {
  return {
    type: WEBP_SUPPORT_UPDATED,
    payload: boolean,
  }
}

export function fetch() {
  return Api.fetchConfig({
    success: (res) => [
      init({
        environment: res.value.environment,
        footerSections: res.value.footerSections,
        pusher: res.value.pusher,
        gtmContainerId: 'GTM-PHM4VP4',
      }),
    ],
    failure: () => init(new Error('errors.http.fetch-failure')),
    version: 1,
  })
}

export function reducer(state = INITIAL_STATE, action) {
  if (action.error) {
    return state
  }

  switch (action.type) {
    case INIT: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case CLIENT_TYPE_UPDATED: {
      return R.merge(state, {clientType: action.payload})
    }

    case COUNTRY_CODE_UPDATED: {
      return R.merge(state, {countryCode: action.payload})
    }

    case DEVICE_PIXLE_RATIO_UPDATED: {
      return R.merge(state, {devicePixleRatio: action.payload})
    }

    case WEBP_SUPPORT_UPDATED: {
      return R.merge(state, {hasWebpSupport: action.payload})
    }

    default: {
      return state
    }
  }
}

export function getApiUrl(state) {
  return R.path(['apiUrl'], state)
}

export function getGqlUrl(state) {
  return R.path(['gqlUrl'], state)
}

export function getCountryCode(state) {
  return R.path(['countryCode'], state)
}

export function getGameServerUrl(state) {
  return R.pathOr('', ['gameServerUrl'], state)
}

export function getEnvironment(state) {
  return R.pathOr('', ['environment'], state)
}

export function getClientType(state) {
  return R.pathOr(Constants.ClientType.UNKNOWN, ['clientType'], state)
}

export function getHasWebpSupport(state) {
  return R.pathOr(false, ['hasWebpSupport'], state)
}

export function getDevicePixleRatio(state) {
  const dpr = R.pathOr(1, ['devicePixleRatio'], state)
  return dpr > 2 ? 2 : dpr
}

export function getImgproxyUrl(state) {
  return R.path(['imgproxyUrl'], state)
}

export function getBabelFiskImgProxyUrl(state) {
  return R.path(['babelFiskImgProxyUrl'], state)
}

export function getI18nProviderUrl(state) {
  return R.path(['i18nProviderUrl'], state)
}

export function getVersion(state) {
  return R.pathOr('', ['version'], state)
}

export const getConfig = Reselect.createStructuredSelector({
  clientType: getClientType,
  environment: getEnvironment,
})

export function getGtmContainerId(state) {
  return R.path(['gtmContainerId'], state)
}

// "Footer" selectors
export function getPaymentProviderImageUrls(state) {
  return R.pathOr(EMPTY_ARRAY, ['footerSections', 'paymentProviders'], state)
}

export function getGameProviderImageUrls(state) {
  return R.pathOr(EMPTY_ARRAY, ['footerSections', 'gameProviders'], state)
}

export function getSocialMediaLinks(state) {
  return R.pathOr(EMPTY_ARRAY, ['footerSections', 'socialMedia'], state)
}

export function getLanguage(state) {
  return R.path(['language'], state)
}

export function getOrigin(state) {
  return R.path(['origin'], state)
}

export function getLanguageFallbackList(state) {
  const language = getLanguage(state)
  // Fallback to en
  return [language, 'en']
}

export function getPusher(state) {
  return R.pathOr(EMPTY_OBJECT, ['pusher'], state)
}

export function getBrand(state) {
  return R.path(['brand'], state)
}

export function isIpInternal(state) {
  return R.pathOr(false, ['isIpInternal'], state)
}
