import * as Configuration from '../configuration'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

export function useDevicePixelRatioListener() {
  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    dispatch(
      Configuration.updateDevicePixleRatio(Math.round(window.devicePixelRatio))
    )
  }, [dispatch])
}
